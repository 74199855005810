import API from './axiosConfig'
import { BU } from './constants'

export default {
  order: {
    get: uuid => API.get(`/customer/order/${uuid}`, { params: { bu: BU } }),
    acceptConditions: (orderId, conditionUrls) =>
      API.post(`/customer/order/${orderId}/acceptConditions`,
        { conditionUrls },
        { params: { bu: BU } })
  },
}
