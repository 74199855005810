import { GET_ORDER, ACCEPT_CONDITIONS } from '../action-types'

export const initialState = {
  order: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDER:
      return { ...state, order: payload }
    case ACCEPT_CONDITIONS:
      return { ...state, order: { ...state.order, otp: payload } }
    default:
      return state
  }
}
