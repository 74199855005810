import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Card } from 'antd'

import { TotalWrapper, Subtotal, Discount, Total } from './styled'
import ProductsList from 'Components/ProductsList'

const OrderCard = props => {
  const { data, device } = props
  const { fullOrderNumber, grossTotal, finalTotal, discountValue, products } =
    data
  const hasDiscount = grossTotal !== finalTotal

  return (
    <Card>
      <h1>
        <FormattedMessage
          id={'orderCard.title'}
          values={{ orderNumber: fullOrderNumber }}
        />
      </h1>
      <ProductsList data={products} device={device} />
      <TotalWrapper device={device}>
        {hasDiscount ? (
          <>
            <Subtotal>
              <FormattedMessage
                id={'orderCard.subtotal'}
                values={{ subtotal: <strong>{grossTotal}€</strong> }}
              />
            </Subtotal>
            <Discount>
              <FormattedMessage
                id={'orderCard.discount'}
                values={{ discount: <strong>{discountValue}%</strong> }}
              />
            </Discount>
          </>
        ) : null}
        <Total>
          <FormattedMessage
            id={'orderCard.total'}
            values={{ total: <strong>{finalTotal}€</strong> }}
          />
        </Total>
      </TotalWrapper>
    </Card>
  )
}
OrderCard.propTypes = {
  data: PropTypes.object,
  device: PropTypes.string,
}
export default OrderCard
