import { GET_ORDER, ACCEPT_CONDITIONS } from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getOrder = uuid => async dispatch => {
  try {
    const res = await api.order.get(uuid)
    const { status, data } = res
    dispatch({ type: GET_ORDER, payload: data })
    return { status }
  } catch (error) {
    return handleServerError(error)
  }
}

export const acceptConditions = (orderId, conditionUrls) => async dispatch => {
  try {
    const res = await api.order.acceptConditions(orderId, conditionUrls)
    dispatch({ type: ACCEPT_CONDITIONS, payload: res.data.otp })
    return { status: res.status }
  } catch (error) {
    handleServerError(error)
    return { status: error.response.status }
  }
}