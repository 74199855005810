import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Checkbox } from 'antd'

import Button from 'Components/Button'

const Condition = props => {
  const { id, url, isEnabled, onChange } = props

  return <div>

    <Button type={'link'}>
      <a href={url} target={'_blank'} rel={'noopener noreferrer'}>
        <FormattedMessage id={'condition.button.view'} />
      </a>
    </Button>
    <Checkbox
      disabled={!isEnabled}
      defaultChecked={!isEnabled}
      onChange={(e) => onChange({ [id]: e.target.checked })}
    >
      <FormattedMessage id={'condition.checkbox.accept'} />
    </Checkbox>
  </div>
}
Condition.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool,
  onChange: PropTypes.func
}
export default Condition