import React from 'react'
import PropTypes from 'prop-types'
import { List, Avatar } from 'antd'

import { PriceWrapper, GrossPrice, FinalPrice } from './styled'
import { FormattedMessage } from 'react-intl'

const ProductsList = props => {
  const { data, device } = props
  return (
    <List
      dataSource={data}
      renderItem={product => {
        const {
          id,
          description,
          imgUrl,
          quantity,
          grossTotalPrice,
          finalTotalPrice,
        } = product
        const hasDiscount = grossTotalPrice !== finalTotalPrice
        return (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  shape={'square'}
                  src={imgUrl}
                  size={device === 'desktop' ? 90 : 'large'}
                />
              }
              title={description}
              description={`Ref. ${id}`}
            />
            <PriceWrapper>
              <p>
                <FormattedMessage
                  id={'productsList.quantity'}
                  values={{ quantity }}
                />
              </p>
              {hasDiscount ? <GrossPrice>{grossTotalPrice}€</GrossPrice> : null}
              <FinalPrice>{finalTotalPrice}€</FinalPrice>
            </PriceWrapper>
          </List.Item>
        )
      }}
    />
  )
}
ProductsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}
export default ProductsList
